/* STYLES FOR LOGIN SCREEN */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600');

amplify-auth-container {
  background: #e5e5e5;
  display: block;
  padding-top: 30px;
}

:root {
  --amplify-font-family: 'Poppins', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --amplify-primary-tint: #63a3df;
  --amplify-primary-color: #1976d2;
  --amplify-primary-shade: #63a3df;
  --amplify-background-color: rgba(255, 255, 255, 1);
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  --container-height: '100vh';
  --margin-bottom: 0px;
  --width: 28.75rem;
  --min-width: 20rem;
  --border-radius: 4px;
  --padding: 10rem 40px 35px 40px;
  --box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  /* --amplify-font-family: $typography-font-family; */
}
